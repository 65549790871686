.LocationSelector {
	display: block;
	max-width: 500px;

	.MuiAutocomplete-listbox {		
		max-height: 360px !important;
	}
}

.MuiAutocomplete-option[data-focus='true'] {
	background-color: rgba(51, 138, 190, 0.24) !important;
	//color: white;
	opacity: 0.9;
}
