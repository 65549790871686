.TasksReportPage {
	.PageHeader {
		//background-color: red;
		//padding-bottom: 20px;
	}

	.empty-arrow {
		position: absolute;
		top: 40px;
		left: 40px;
		z-index: 2;
	}
}
