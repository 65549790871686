.TaskReportList {
	display: block;
	position: relative;
	min-height: 400px;

	.pagination {		
		position: absolute;
		top: -20px;
	}

	/*
	.pagination {
		position: absolute;
		top: 20px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: fit-content; 
	}
	*/
}

@import './components/ReportListItem/style.scss';
