
.SandboxPage {

	#testContainer {
		height: 500px;
		width: calc(100% - 360px);
		margin-left: 10px;
	}

}
