

.TaskPeriodSelectionBar {
	
	.SelectionButtons {
		.MuiToggleButtonGroup-root {
			.ToggleButton {
				&:first-child {
					.MuiToggleButton-root {
						border-top-left-radius: 4px;
						border-bottom-left-radius: 4px;
					}
				}

				&:last-child {
					.MuiToggleButton-root {
						border-top-right-radius: 4px;
						border-bottom-right-radius: 4px;
					}
				}

				&:hover {
					background-color: #005a82;
					color: white
				}

				.MuiToggleButton-root {
					border-radius: 0;
					border: 1px solid rgba(0, 0, 0, 0.12);
					margin: 0;
					padding: 6px 12px;
					text-transform: none;
					font-size: 14px;

					&.Mui-selected {
						background-color: color('primary', 'main');
						

						&:hover {
							background-color: color('primary', 'dark');							
						}
					}

					&:hover {
						background-color: rgba(0, 0, 0, 0.04);
					}
				}
			}
		}
	}
}
