.FieldsEditor {
	padding: 2px;
	padding-top: 8px;

	.rstcustom__highlight {
		background-color: transparent;
	}

	.rstcustom__rowTitle {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		font-weight: 400;
	}

	.item-icon {
		margin-right: 4px;
	}

	border-top: rgba(0, 0, 0, 0.12) 1px solid;
	border-bottom: rgba(0, 0, 0, 0.12) 1px solid;
}
