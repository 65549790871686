.PositionSelector {
	display: block;
	max-width: 500px;
}

/*
.MuiAutocomplete-root {
	z-index: 10000;
}
.MuiAutocomplete-popper {
	.MuiPaper-root {
		background-color: red;
	}
}
*/

.MuiAutocomplete-option[data-focus='true'] {
	background-color: rgba(51, 138, 190, 0.24) !important;
	//color: white;
	opacity: 0.9;
	//background-color: red;
}


// .MuiAutocomplete-popperDisablePortal {
// 	//background-color: rgba(51, 138, 190, 0.24) !important;
// 	//color: white;
	
// 	background-color: red;
// }
// .MuiAutocomplete-listbox {
// 	background-color: yellow;
// 	//height: 200px;
// }