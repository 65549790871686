.ResetPassword {
	margin: 0 0 20px;
	padding: 10px;
	text-align: left;
	border: none;
	background: #f6f2ef;
	font-size: 17px;
	font-family: 'Graphik', 'Helvetica Neue', helvetica, 'Apple Color Emoji', arial, sans-serif !important;
	line-height: 1.4;

	.ops.brand-wrapper {
		background-image: url(/images/opsperfect.png);
		background-position: center -10px;
		/*background-size: 186px 73px;*/
		/*background-size: 90px 90px;*/
	}

	.txt--left {
		text-align: left;
	}

	.spacing {
		margin-top: 1.25em !important;
		margin-bottom: 1.25em !important;
	}

	.list--bulleted {
		margin: 0;
		padding: 0 0 0 1.5em;
		list-style: disc;
	}

	hr {
		border-top-style: initial;
		border-right-style: initial;
		border-left-style: initial;
		border-top-color: initial;
		border-right-color: initial;
		border-left-color: initial;
		border-width: 0px 0px 1px;
		border-image: initial;
		border-bottom: 1px solid rgb(217, 217, 217);
	}

	.txt--small {
		font-size: 0.85em;
	}

	.no--bottom {
		margin-bottom: 0px !important;
	}

	.brand-wrapper {
		margin: 45px auto 20px auto;
		padding: 110px 0 0;
		text-align: center;
		width: 100%;
		max-width: 988px;
		background: no-repeat center 30px;
		background-size: 280px;
	}

	.ops .balloon {
		border-radius: 20px;
		max-width: 448px;
		box-sizing: border-box;
	}

	.field {
		margin-bottom: 13.6px;
		text-align: left;
	}
	h1 {
		font-size: 20px;
		font-weight: bold;
		line-height: 1.2;
		margin: 0 0 15px;
		text-align: center;
	}
	.field__label {
		font-weight: bold;
	}

	.input--text {
		width: 100%;
		max-width: 100%;
		border-radius: 10px;
		border: 1px solid #bfbfbf;
		padding: 10px;
		background-color: #fff;
		-webkit-appearance: none;
		-moz-appearance: none;
		-webkit-transition: box-shadow 0.15s ease-in-out;
		-moz-transition: box-shadow 0.15s ease-in-out;
		-ms-transition: box-shadow 0.15s ease-in-out;
		transition: box-shadow 0.15s ease-in-out;
		text-decoration: none;
		font-family: inherit;
		font-weight: inherit;
		font-size: 16px;
		color: #000;
		display: inline-block;
		line-height: inherit;
		white-space: normal;
		vertical-align: middle;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.balloon {
		position: relative;
		max-width: 380px;
		margin: 0 auto;
		background: #fff;
		padding: 30px;
		border-radius: 6px;		
		box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
	}

	.action_button.primary {
		background-color: #3187b2;
		border-color: #3187b2;
		border-bottom-color: rgba(0, 0, 0, 0.15);
		color: #fff !important;
	}

	.action_button {
		display: inline-block;
		width: 100%;
		max-width: 100%;
		padding: 10px 15px;
		border: 1px solid #bfbfbf;
		border-color: rgba(0, 0, 0, 0.15);
		border-bottom-width: 2px;
		border-radius: 10px;
		background-color: #fff;
		text-decoration: none;
		text-align: center;
		font-family: inherit;
		font-weight: inherit;
		font-weight: bold;
		font-size: 17px;
		color: #000;
		line-height: inherit;
		vertical-align: middle;
		white-space: normal;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		-webkit-transition: box-shadow 0.15s ease-in-out;
		-moz-transition: box-shadow 0.15s ease-in-out;
		-ms-transition: box-shadow 0.15s ease-in-out;
		transition: box-shadow 0.15s ease-in-out;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		text-transform: none !important;
	}

	.input--text:focus {
		outline: none;
		border-color: #a4d2ff;
		box-shadow: 0 0 6px rgba(27, 106, 201, 0.5);
	}

	.loading > .hide--when-loading {
		display: none;
	}

	.hide--when-loading {
		display: inline;
	}

	.hide--unless-loading {
		display: none;
	}

	.loading .hide--unless-loading {
		display: block !important;
	}

	.hide--unless-errored {
		display: none;
	}

	.errored .hide--unless-errored {
		display: block !important;
	}

	.footer {
		margin: 20px auto;
		font-size: 80%;
	}

	a {
		color: #3187b2;
		text-decoration: none;
	}

	a:hover {
		color: #3187b2;
		text-decoration: underline;
	}

	.txt--center {
		text-align: center;
	}
	h2 {
		font-size: 17px;
		font-weight: bold;
	}

	.txt--red {
		color: #d4442e;
	}

	.flash {
		margin-bottom: 13.6px;
		padding: 13.6px;
		background-color: #fffae5;
		border: 1px solid #ffeb99;
		border-radius: 10px;
	}

	.loader {
		border: 16px solid #f3f3f3; /* Light grey */
		border-top: 16px solid #3187b2;
		border-bottom: 16px solid #595a5b;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		animation: spin 2s linear infinite;
		margin: auto;
	}

	.wrap {
		width: 100%;
		position: relative;
		top: 30px;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.Forgot {
	margin: 0 0 20px;
	padding: 10px;
	text-align: left;
	border: none;
	background: #f6f2ef;
	font-size: 17px;
	font-family: 'Graphik', 'Helvetica Neue', helvetica, 'Apple Color Emoji', arial, sans-serif !important;
	line-height: 1.4;

	.ops.brand-wrapper {
		background-image: url(/images/opsperfect.png);
		background-position: center -10px;
		/*background-size: 186px 73px;*/
		/*background-size: 90px 90px;*/
	}

	.spacing {
		margin-top: 1.25em !important;
		margin-bottom: 1.25em !important;
	}

	hr {
		border-top-style: initial;
		border-right-style: initial;
		border-left-style: initial;
		border-top-color: initial;
		border-right-color: initial;
		border-left-color: initial;
		border-width: 0px 0px 1px;
		border-image: initial;
		border-bottom: 1px solid rgb(217, 217, 217);
	}

	.txt--small {
		font-size: 0.85em;
	}

	.no--bottom {
		margin-bottom: 0px !important;
	}

	.brand-wrapper {
		margin: 45px auto 20px auto;
		padding: 110px 0 0;
		text-align: center;
		width: 100%;
		max-width: 988px;
		background: no-repeat center 30px;
		background-size: 280px;
	}

	.ops .balloon {
		border-radius: 20px;
		max-width: 448px;
		box-sizing: border-box;
	}

	.field {
		margin-bottom: 13.6px;
		text-align: left;
	}
	h1 {
		font-size: 20px;
		font-weight: bold;
		line-height: 1.2;
		margin: 0 0 15px;
		text-align: center;
	}
	.field__label {
		font-weight: bold;
	}

	.input--text {
		width: 100%;
		max-width: 100%;
		border-radius: 10px;
		border: 1px solid #bfbfbf;
		padding: 10px;
		background-color: #fff;
		-webkit-appearance: none;
		-moz-appearance: none;
		-webkit-transition: box-shadow 0.15s ease-in-out;
		-moz-transition: box-shadow 0.15s ease-in-out;
		-ms-transition: box-shadow 0.15s ease-in-out;
		transition: box-shadow 0.15s ease-in-out;
		text-decoration: none;
		font-family: inherit;
		font-weight: inherit;
		font-size: 16px;
		color: #000;
		display: inline-block;
		line-height: inherit;
		white-space: normal;
		vertical-align: middle;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.balloon {
		position: relative;
		max-width: 380px;
		margin: 0 auto;
		background: #fff;
		padding: 30px;
		border-radius: 6px;		
		box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
		/* box-shadow: 0 -1px 10px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 10%), 0 10px 30px #f3ece8; */
	}

	.action_button.primary {
		background-color: #3187b2;
		border-color: #3187b2;
		border-bottom-color: rgba(0, 0, 0, 0.15);
		color: #fff !important;
	}

	.action_button {
		display: inline-block;
		width: 100%;
		max-width: 100%;
		padding: 10px 15px;
		border: 1px solid #bfbfbf;
		border-color: rgba(0, 0, 0, 0.15);
		border-bottom-width: 2px;
		border-radius: 10px;
		background-color: #fff;
		text-decoration: none;
		text-align: center;
		font-family: inherit;
		font-weight: inherit;
		font-weight: bold;
		font-size: 17px;
		color: #000;
		line-height: inherit;
		vertical-align: middle;
		white-space: normal;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		-webkit-transition: box-shadow 0.15s ease-in-out;
		-moz-transition: box-shadow 0.15s ease-in-out;
		-ms-transition: box-shadow 0.15s ease-in-out;
		transition: box-shadow 0.15s ease-in-out;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		text-transform: none !important;
	}

	.input--text:focus {
		outline: none;
		border-color: #a4d2ff;
		box-shadow: 0 0 6px rgba(27, 106, 201, 0.5);
	}

	.loading > .hide--when-loading {
		display: none;
	}

	.hide--when-loading {
		display: inline;
	}

	.hide--unless-loading {
		display: none;
	}

	.loading .hide--unless-loading {
		display: block !important;
	}

	.hide--unless-errored {
		display: none;
	}

	.errored .hide--unless-errored {
		display: block !important;
	}

	.footer {
		margin: 20px auto;
		font-size: 80%;
	}

	a {
		color: #3187b2;
		text-decoration: none;
	}

	a:hover {
		color: #3187b2;
		text-decoration: underline;
	}

	.txt--center {
		text-align: center;
	}
	h2 {
		font-size: 17px;
		font-weight: bold;
	}

	.txt--red {
		color: #d4442e;
	}

	.flash {
		margin-bottom: 13.6px;
		padding: 13.6px;
		background-color: #fffae5;
		border: 1px solid #ffeb99;
		border-radius: 10px;
	}

	.loader {
		border: 16px solid #f3f3f3; /* Light grey */
		border-top: 16px solid #3187b2;
		border-bottom: 16px solid #595a5b;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		animation: spin 2s linear infinite;
		margin: auto;
	}

	.wrap {
		width: 100%;
		position: relative;
		top: 30px;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.LogIn {
	margin: 0 0 20px;
	padding: 10px;
	text-align: left;
	border: none;
	background: #f6f2ef;
	font-size: 17px;
	font-family: 'Graphik', 'Helvetica Neue', helvetica, 'Apple Color Emoji', arial, sans-serif !important;
	line-height: 1.4;

	.ops.brand-wrapper {
		background-image: url(/images/opsperfect.png);
		background-position: center -10px;
		/*background-size: 186px 73px;*/
		/*background-size: 90px 90px;*/
	}

	.brand-wrapper {
		margin: 45px auto 20px auto;
		padding: 110px 0 0;
		text-align: center;
		width: 100%;
		max-width: 988px;
		background: no-repeat center 30px;
		background-size: 280px;
	}

	.ops .balloon {
		border-radius: 20px;
		max-width: 448px;
		box-sizing: border-box;
	}

	.field {
		margin-bottom: 13.6px;
		text-align: left;
	}
	h1 {
		font-size: 20px;
		font-weight: bold;
		line-height: 1.2;
		margin: 0 0 15px;
		text-align: center;
	}
	.field__label {
		font-weight: bold;
	}

	.input--text {
		width: 100%;
		max-width: 100%;
		border-radius: 10px;
		border: 1px solid #bfbfbf;
		padding: 10px;
		background-color: #fff;
		-webkit-appearance: none;
		-moz-appearance: none;
		-webkit-transition: box-shadow 0.15s ease-in-out;
		-moz-transition: box-shadow 0.15s ease-in-out;
		-ms-transition: box-shadow 0.15s ease-in-out;
		transition: box-shadow 0.15s ease-in-out;
		text-decoration: none;
		font-family: inherit;
		font-weight: inherit;
		font-size: 16px;
		color: #000;
		display: inline-block;
		line-height: inherit;
		white-space: normal;
		vertical-align: middle;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.balloon {
		position: relative;
		max-width: 380px;
		margin: 0 auto;
		background: #fff;
		padding: 30px;
		border-radius: 6px;
		/* box-shadow: 0 -1px 10px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 10%), 0 10px 30px #f3ece8; */
		box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
	}

	.action_button.primary {
		background-color: #3187b2;
		border-color: #3187b2;
		border-bottom-color: rgba(0, 0, 0, 0.15);
		color: #fff !important;
	}

	.action_button {
		display: inline-block;
		width: 100%;
		max-width: 100%;
		padding: 10px 15px;
		border: 1px solid #bfbfbf;
		border-color: rgba(0, 0, 0, 0.15);
		border-bottom-width: 2px;
		border-radius: 10px;
		background-color: #fff;
		text-decoration: none;
		text-align: center;
		font-family: inherit;
		font-weight: inherit;
		font-weight: bold;
		font-size: 17px;
		color: #000;
		line-height: inherit;
		vertical-align: middle;
		white-space: normal;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		-webkit-transition: box-shadow 0.15s ease-in-out;
		-moz-transition: box-shadow 0.15s ease-in-out;
		-ms-transition: box-shadow 0.15s ease-in-out;
		transition: box-shadow 0.15s ease-in-out;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		text-transform: none !important;
	}

	.input--text:focus {
		outline: none;
		border-color: #a4d2ff;
		box-shadow: 0 0 6px rgba(27, 106, 201, 0.5);
	}

	.loading > .hide--when-loading {
		display: none;
	}

	.hide--when-loading {
		display: inline;
	}

	.hide--unless-loading {
		display: none;
	}

	.loading .hide--unless-loading {
		display: block !important;
	}

	.hide--unless-errored {
		display: none;
	}

	.errored .hide--unless-errored {
		display: block !important;
	}

	.footer {
		margin: 20px auto;
		font-size: 80%;
	}

	a {
		color: #3187b2;
		text-decoration: none;
	}

	a:hover {
		color: #3187b2;
		text-decoration: underline;
	}

	.txt--center {
		text-align: center;
	}
	h2 {
		font-size: 17px;
		font-weight: bold;
	}

	.txt--red {
		color: #d4442e;
	}

	.flash {
		margin-bottom: 13.6px;
		padding: 13.6px;
		background-color: #fffae5;
		border: 1px solid #ffeb99;
		border-radius: 10px;
	}

	.loader {
		border: 16px solid #f3f3f3; /* Light grey */
		border-top: 16px solid #3187b2;
		border-bottom: 16px solid #595a5b;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		animation: spin 2s linear infinite;
		margin: auto;
	}

	.wrap {
		width: 100%;
		position: relative;
		top: 30px;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
