.location-selector-popover {
	z-index: 10000;

	.popover-inner {
		background-color: white;
		//border: 1px solid red;

		padding: 20px;
		min-width: 500px;
	}
}

.LocationPopoutSelector {
	display: inline-block;

	.location-selector-control {
		display: flex;
		flex-direction: column;

		.editable-caption-row {
			//background-color: yellow;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}

		label {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.54);
		}

		.filter-link {
			display: inline-block;
			cursor: pointer;
		}
	}
}
