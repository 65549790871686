
@import "./components/UserGroupFilter/style.scss";
@import "./components/UserAccessLevelFilter/style.scss";
@import "./components/GroupList/style.scss";
@import "./components/UserViewSelector/style.scss";


.UserFilter {



	.filter-bar {
		border-radius: 2px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
		background-color: white;
	}

	.buttonbar {

		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		padding-left: 15px;
		padding-right: 15px;
		background-color: white;

		.buttonbar-left {
			margin-left: 0px;
			min-width: 50%
		}

		.buttonbar-right {
			margin-left: auto;
		}

	}

	.filter-toolbar {

		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;

		.toolbar-left {
			margin-left: 0px;
			min-width: 50%
		}

		.toolbar-right {
			margin-left: auto;
		}
	}

	.filter-finds {
		background-color: white;
		padding-top: 10px;
	}

	.filter-row {
		flex: 1;
		flex-direction:row;
	}


	.btn {
		margin: 8px;

		.ico {
			margin-right: 8px;
			font-size: 20px;
		}
	}
	color: black;

}

.UserFilterGroup {
	margin-right: 10px;
	display: inline-block;
	font-size: 16px;

	.UserViewSelector {

		margin-top: 8px;
	}
}

