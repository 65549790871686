.RoutineAdder {
	display: block;
	width: 100%;
	//background-color: red;	

	.header-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		//padding-right: 10px;
		//background-color: yellow;
	}

	.btn-clearform {		
		//margin-right: 20px;
	}

	&.op-what-dialog {
		//background-color: cyan;
		position: relative;
		max-width: 1200px;
		
		margin: 12px auto;
	}

	&.op-definition-dialog {
		//background-color: lightblue;
		position: relative;
		max-width: 680px;		
		margin: 12px auto;
	}

	.main-title {
		//background-color: red;
		display: flex;
		flex-direction: column;
		align-items: center;
		//margin: auto auto;
		h1 {
			//background-color: green;
			max-width: fit-content;
			font-size: 32px;
		}
	}

	.btn-next {
		span {
			font-size: 20px;
		}
	}

	.btn-complete{
		span {
			font-size: 20px;
		}
	}

	form {
		width: 94%;
	}

	.routine-adder-card {		
		max-width: 600;
		margin: auto auto;		
		border-radius: 10px;

		.number-wrapper {
			padding-left: 10px;
			padding-right: 12px;
		}

		.section {			
			margin-bottom: 16px;
			padding-right: 60px;		
			.contents {
				width: 100%;
			}
		}
	}

	.routine-what-card {		
		width: 100%;
		//margin: auto auto;		
		border-radius: 10px;
		//background-color: green;

		.number-wrapper {
			//padding-left: 10px;
			padding-right: 12px;
			//background-color: red;
			margin-left: -6px;
		}

		.section {		
			margin-bottom: 16px;			
			.contents {
				width: 100%;		
				.FormEditor {									
					//margin-left: -10px;
					//margin-right: -40px;
				}
			}
		}			
	}
	
	.add-op {
		padding-right: 5px;
	}

	h2 {
		margin: 0px;
		font-weight: initial;
	}

	.page-header {
		background-color: #f1f5f9;
		padding: 15px 30px;
	}

	

	.bullet-point {		
		margin-bottom: 1rem;
		font-weight: 500;
		font-size: 1.3rem;
	}

	.TaskTypeSelector {
		width: 50%;
		margin-top: 0px;
	}

	

	// .LocationAssigner {
	// 	padding-top: 10px;
	// 	padding-bottom: 20px;
	// }

	// .AccessLevelSelector {
	// 	.Selector {
	// 		width: 50%;
	// 	}
	// }

	.card-text {
		margin-top: 0px;
		margin-bottom: 2rem;
		padding: 0px 14px;
	}

	

	// .TeamSelector {
	// 	margin-top: 1rem;
	// }

	// .loginType {
	// 	margin-left: -16px;
	// }

	.or {
		padding: 5px;
		font-weight: lighter;
	}

	.or-btn {
		padding-left: 16px;
	}

	.add-user-alt {
		padding-left: 5px;
	}

	

	.number {
		color: #fff;
	}

	

	.buttons {
		padding-top: 2rem;
		padding-left: 56px;
	}

	.what-next {
		font-size: larger;
		margin-left: auto;
		margin-right: auto;
		max-width: 68ch;
		text-align: center;
	}
}
