.TaskListHeader {
   //display: block;
   //background-color: red;
   min-height: 72px;

   .SelectionBar {
      //background-color: yellow;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: baseline;
      //padding: 8px;

      .selectionbar-left {
         margin-left: 0px;
         min-width: 50%;
      }

      .selectionbar-right {
         margin-left: auto;

         h4 {
            margin: 0px;
            padding: 6px 0px;
         }
      }

      .SelectActions {
         display: flex;
         flex-flow: row nowrap;
         justify-content: space-between;
         align-items: baseline;
         .title {
            //padding-left: 8px;
            font-size: 14px;
         }
      }
   }
}
