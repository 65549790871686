.StaffPositionsSetter {
	z-index: 1;

	.MuiDialog-paper {
		width: 30rem;
		overflow-y: visible;
	}

	.PositionSelector {
		z-index: 1000;
	}

	.actions {
		padding-right: 18px;
		padding-bottom: 12px;
	}
	.or-btn {
		padding-left: 8px;
		margin-right: -12px;
	}
}
