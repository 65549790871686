.RoutineSchedule {
	display: block;

	.RoutineOccurrencePicker {
		margin-bottom: 6px;
	}

	.hidden {
		visibility: hidden;
	}
}
