.SearchBox {
	display: block;

	.MuiOutlinedInput-root {
		border-radius: 50px;
	}

	.MuiOutlinedInput-input {
		padding-top: 12px;
		padding-bottom: 12px;
		padding-right: 6px;
	}

	.clear-btn {
		padding: 6px;
	}

	.hide-btn {
		visibility: hidden;
	}
}
