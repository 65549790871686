.PositionsActionBar {
	display: block;
	position: relative;

	.bg-primary-diabled {
		background-color: lightgray;
	}

	.TeamSelector {
		//padding-top: 10px;
		margin: 10px;
		min-width: 300px;
	}

	.actions {
		//background-color: yellow;
		padding-left: 54px;
		//display: flex;
		//flex-direction: row;
		//justify-content: flex-start;
		//align-items: center;
	}

	.action-panel {
		padding: 8px;
		//position: absolute;
		//background-color: #f6f2f4;
		background-color: #f4efeb;
		//background-color: red;

		//background-color: #fffadf;
		//top: 100;
		//left: 0;
		z-index: 1;
		width: 100%;
	}

	.suggestion-box {
		position: absolute;
		z-index: 2;
		min-width: 200px;
	}

	.delete-btn {
		background-color: orange !important;
	}
}
