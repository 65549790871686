.MonthlySelectedDaysPicker {
	background-color: #fff;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 8px;
	padding-right: 4px;
	max-width: 250px;
	.MuiToggleButtonGroup-root {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;

		.ToggleButton {
			margin-right: 2px;
			margin-bottom: 4px;
			height: 30px;

			.date-btn {
				width: 42px;
				margin-right: 4px;
			}

			.last-btn {
				width: 42px;
			}

			.day-btn {
				margin-top: 6px;
				min-width: unset;
				width: 30px;
			}
		}
	}
}
