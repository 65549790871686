html,
body {
	height: 100%;
	background-color: #f6f2ef;
}

#root {
	display: flex;
	width: 100%;
	height: 100%;
}

.app-main {
	display: flex;
	width: 100%;
	height: 100%;
}

.app-main-content {
	flex: 1;

	width: 100%;
}

.app-wrapper {
	//padding: 24px;
	padding-bottom: 24px;
	padding-left: 48px;
	padding-right: 48px;
	padding-top: 6px;
	width: 100%;
}
@media screen and (max-width: 575px) {
	.app-wrapper {
		padding: 10px;
	}
}

/*
.animation-duration-3 {
	animation-duration: 0.3s;
}

.slideInUpTiny {
	animation-name: slideInUpTiny;
}
*/

.align-items-center {
	align-items: center !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.justify-content-evenly {
	justify-content: space-evenly !important;
}

.flex-column {
	flex-direction: column !important;
}

.d-flex {
	display: flex !important;
}

.flex-row {
	flex-direction: row !important;
}

.size-80 {
	height: 80px !important;
	width: 80px !important;
	line-height: 80px;
}

.size-40 {
	height: 40px !important;
	width: 40px !important;
	line-height: 40px;
}

.size-30 {
	height: 30px !important;
	width: 30px !important;
	line-height: 30px;
}

.size-20 {
	height: 20px !important;
	width: 20px !important;
	line-height: 20px;
}

.size-120 {
	height: 120px !important;
	width: 120px !important;
	line-height: 120px;
}

h1,
.h1 {
	font-size: 24px;
	line-height: 1.2;
}

.m-0 {
	margin: 0 !important;
}

.app-login-container {
	position: relative;
	max-width: 680px;
	width: 94%;
	margin: 0 auto;
}
.app-login-container .loader-view {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 0;
	bottom: 0;
	z-index: 2;
}

.dialog-paper {
	background-color: #f6f2ef !important; // background
}

.center-dialog {
	position: relative;
	max-width: 680px;
	width: 94%;
	margin: auto auto;
}

.top-dialog {
	position: relative;
	max-width: 680px;
	width: 94%;
	margin: 60px auto;
}

.layout-fill {
	margin: 0;
	width: 100%;
	min-height: 100%;
	height: 100%;
}

.module-list-item {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-items: center;
	align-items: center;
	padding: 15px 28px;
	border-bottom: solid 1px #e9ecef;
	z-index: 5;
	position: relative;
	cursor: pointer;
}

.module-list-icon {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-items: center;
	align-items: center;
	padding-right: 10px;
}

.module-list-info {
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1 0;
	flex: 1 1 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	max-width: calc(100% - 169px);
}

@media screen and (max-width: 575px) {
	.module-list-item {
		padding: 10px 12px;
	}
	.module-list-info {
		max-width: 100%;
	}
	.module-box-header .nav-searchbox.quick-menu .dropdown-menu {
		right: auto !important;
		left: 0 !important;
	}
	.module-box-header .search-bar.right-side-icon {
		min-width: 200px;
	}
	.module-box-header .search-bar.right-side-icon .form-control {
		border-radius: 0.25rem;
	}
	.module-box-header .nav-searchbox.quick-menu .dropdown-menu:after {
		right: auto;
		left: 9px;
	}
	.module-box-header .nav-searchbox.quick-menu .dropdown-menu:before {
		right: auto;
		left: 9px;
	}
	.module-box-header .search-bar {
		padding-right: 2px;
	}
}

.badge {
	font-weight: 400;
	padding: 3px 8px;
	line-height: inherit;
	margin-bottom: 6px;
	border-radius: 2px;
	display: inline-block;
	font-size: 80%;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
}
.badge:not(:last-child) {
	margin-right: 15px;
}
table .badge {
	margin-bottom: 0;
}

.badge-pill {
	border-radius: 20px;
}

.badge-border {
	border: 2px solid !important;
}

.text-white {
	color: #ffffff !important;
}

.bg-secondary {
	background-color: #b27c31 !important;
}

.MuiTableCell-footer {
	border-bottom: 0 !important;
}

.app-logo img {
	height: 40px;
	//width: 13.6rem;
	//height: 3.9rem;
}

.card-header {
	padding: 20px 24px;
	margin-bottom: 0;
	background-color: #f1f5f9;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
	border-top: 0;
}

.btn {
	text-transform: none !important;
	border-radius: 25px !important;
}

.btn.disabled,
.btn:disabled {
	opacity: 0.65;
	box-shadow: none;
}

.bg-primary {
	background-color: #5086a0 !important;
}

.app-main-content-wrapper {
	flex: 1;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

.app-footer {

	
	position: absolute;
	width: 100%;
	bottom: 0px;

	padding: 0 24px;
	-webkit-box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15);
	-o-box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15);
	//background-color: #f8f9fa;
	color: #a1a1a1;
	line-height: 42px;
	min-height: 42px;
	height: 42px;
	max-height: 42px;

	.ico {
		display: inline-block;
		font-size: 20px;
		position: relative;
	}
}

.app-footer-text {
	position: relative;
	z-index: 0;;
}

.app-footer-text:hover {
	
	z-index: 1;
}


.app-footer-copy {
	width: 100%;
	bottom: 0px;

	padding: 0 24px;
	-webkit-box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15);
	-o-box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15);
	//background-color: #f8f9fa;
	color: #a1a1a1;
	line-height: 42px;
	min-height: 42px;
	height: 42px;
	max-height: 42px;

	.ico {
		display: inline-block;
		font-size: 20px;
		position: relative;
	}
}

.card-body {
	flex: 1 1 auto;
	padding: 24px !important;
}
