.TaskReportEditor {
 
   .report-divider {
		margin-top: 20px;
		margin-bottom: 0px;

      .MuiDivider-root {
         max-width: 75%;         
     }      
	}
}