.who-editing-popover {
	z-index: 10000 !important;
	

	.popover-inner {
		background-color: white;
		max-width: 600px;

		.canvas {
			//padding: 20px;
		}

		.who-selection {
			flex: 1;
			//padding: 2px;
			//width: 342px;

			//background-color: red;
			//min-width: 400px;
			//max-width: 600px;
			h3 {
				margin: 0;
			}

			.who-content {
				
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-end;

				.PeopleSelector {
					width: 100%;
				}
			}

			.option {

				//background-color: green;
				span {
					padding-top: 0px;
					padding-bottom: 2px;
				}
			}

			.PeopleSelector {				

				.MuiAutocomplete-listbox {
					//background-color: cyan;
					max-height: 280px !important;
				}
			}
		}

	}
}

.PeopleAssigner {
	display: inline-block;
	//border: 1px solid lightgray;

	.assign-control {
		display: flex;
		flex-direction: column;

		.editable-caption-row {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}

		label {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.54);
		}

		.filter-link {
			display: inline-block;
			cursor: pointer;
		}
	}
}