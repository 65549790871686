.ButtonMenu {
	display: block;

	.circle {
		border: 2px solid #d0d0d0;
		//border: 2px solid red;
		//background-color: #ffffff;
		//height: 100px;
		border-radius: 50%;
		//width: 100px;
	}
}
