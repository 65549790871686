.LocationsEmptyPlaceholder {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 20px;

	.info-box {
		background-color: #fffadf;
		width: 60%;
		padding: 20px;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.heading {
			margin-top: 0px;
		}
	}
}
