$white: #fff !default;

//Sizes Variables

$size-20: 20px !default;
$size-30: 30px !default;
$size-40: 40px !default;
$size-50: 50px !default;
$size-60: 60px !default;
$size-80: 80px !default;
$size-100: 100px !default;
$size-120: 120px !default;

// ==============================================
// 3 Dots
// ==============================================

$dotWidth: 8px;
$dotHeight: 8px;
$dotRadius: calc($dotWidth / 2);

//$dotColor: #7d3006;
//$dotColor: #000;
$dotColor: #455a64;
//$dotColor: #C9C9C9;
//$dotColor: #cdcdcd;
$dotBgColor: $dotColor;
$dotBeforeColor: $dotColor;
$dotAfterColor: $dotColor;

$dotSpacing: $dotWidth + calc($dotWidth / 2);

$toolbar-height: 60px; 