.mobile-frame {
        width: 375px;
        /* Adjust this width to match the desired mobile device width */
        height: 667px;
        ;
        /* Adjust this height accordingly */
        //border: 1px solid #000;
        overflow: visible;
        position: relative;
        margin-bottom: 60px;
        ;
        //background-color: red;
}

.iframe-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
}

.iframe-container iframe {
        padding-top: 20px;
        width: 100%;
        height: 100%;
        border: none;
}

.mobile__outline {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 24.8rem;
        flex: 0 0 24.8rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        //width: 24.8rem;
        //height: 50.5rem;
        width: 375px;
        height: 667px;
        border-radius: 3rem;
        border: 1.1rem solid #000;
        background-color: #f5f3f7;
        -webkit-box-shadow: 0 18px 70px -23px rgba(0, 0, 0, 0.45);
        box-shadow: 0 18px 70px -23px rgba(0, 0, 0, 0.45);
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-animation: mobile-animation 0.3s 1s;
        animation: mobile-animation 0.3s 1s;
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
        -webkit-transition: -webkit-transform 0.4s ease-in-out, -webkit-box-shadow 0.4s ease-in-out;
        transition: -webkit-transform 0.4s ease-in-out, -webkit-box-shadow 0.4s ease-in-out;
        transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
        transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out, -webkit-box-shadow 0.4s ease-in-out;
}

@-webkit-keyframes mobile-animation {
        from {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
        }

        to {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
        }
}

@keyframes mobile-animation {
        from {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
        }

        to {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
        }
}

@media only screen and (max-width: 800px) {
        .mobile__outline {
                -webkit-box-flex: 0;
                -ms-flex: none;
                flex: none;
                margin-bottom: 6.7rem;
        }
}

.mobile__outline::before {
        content: "";
        display: block;
        position: absolute;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        top: -1.1rem;
        background-color: #000;
        width: 12.9rem;
        height: 2.9rem;
        border-radius: 0 0 1.5rem 1.5rem;
        z-index: 8;
}

//  @media only screen and (min-width: 801px) {
//    .mobile__outline:hover {
//      -webkit-transform: scale(1.2);
//              transform: scale(1.2);
//      -webkit-box-shadow: 0 0 90px -23px rgba(0, 0, 0, 0.15);
//              box-shadow: 0 0 90px -23px rgba(0, 0, 0, 0.15);
//    }
//  }

@media only screen and (min-width: 801px) {
        .mobile__outline_pop:hover {
                -webkit-transform: scale(1.05);
                transform: scale(1.05);
                -webkit-box-shadow: 0 0 90px -23px rgba(0, 0, 0, 0.15);
                box-shadow: 0 0 90px -23px rgba(0, 0, 0, 0.15);
        }
}