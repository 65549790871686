.MonthlySelectedDatesPicker {
	background-color: #fff;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 8px;
	padding-right: 4px;
	max-width: 250px;
	.MuiToggleButtonGroup-root {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;

		.ToggleButton {
			margin-right: 4px;
			margin-bottom: 4px;
			width: 30px;
			height: 30px;

			.last-btn {
				width: 124px;
				margin-left: 6px;
			}

			.rounded {
				min-width: unset;
			}
		}
	}
}
