.OptionThenTypePicker {
   display: block;

   .picker-control {
      display: flex;
      flex-direction: column;

      .editable-caption-row {
         //background-color: yellow;
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         align-items: center;
         font-size: 16px;
      }

      label {
         font-size: 12px;
         color: rgba(0, 0, 0, 0.54);
      }

      .add-link {
         display: inline-block;
         color: #0047b3;
         cursor: pointer;
      }
   }
}

.option-then-type-picker-editing-popover {
	z-index: 10000;
	.popover-inner {
		.popover-body {
			padding: 0px;
		}
	}
}