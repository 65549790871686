
.InlineTextEditor {
	display: inline-block;

	.edit-link {
		border-bottom: 1px dotted #477b96;
		cursor: pointer;
	}

	.name {
		//background-color: red;
		margin: 0px;
	}
}
