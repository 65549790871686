
.GroupList {


	.col-letter {
		max-width: 60px;

		.letter {
			margin: 8px;
		}
	}

	.col-groups {

		margin-top: 4px;

		.group-chip {
			margin: 8px;
		}
	}
}

