.TeamAdder {
	.MuiDialog-paper {
		width: 30rem;
		max-height: 40%;
	}

	.teamItem {
		&.editing {
			background-color: #f6f2ef;
			border-bottom: 1px solid #e5e5e5;
		}

		&.errored {
			//border-color: red !important;
			border: 1px solid red;
		}
	}

	.btn-add_team {
		margin-left: 0.6em;
	}
}
