.FiltersPage {
	display: block;

	.filter-box {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
}
