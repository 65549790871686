.StaffAdder {
	.add-user {
		padding-right: 5px;
	}

	h2 {
		margin: 0px;
		font-weight: initial;
	}

	.page-header {
		background-color: #f1f5f9;
		padding: 15px 30px;
	}

	.LocationAssigner {
		padding-top: 10px;
		padding-bottom: 20px;
	}

	.AccessLevelSelector {
		.Selector {
			width: 50%;
		}
	}

	.card-text {
		margin-top: 0px;
		margin-bottom: 2rem;
		padding: 0px 14px;
	}

	.bullet-point {
		margin-bottom: 1rem;
		font-weight: 500;
		font-size: 1.1rem;
	}

	.TeamSelector {
		margin-top: 1rem;
	}

	.loginType {
		margin-left: -16px;
	}

	.or {
		padding: 5px;
		font-weight: lighter;
	}

	.or-btn {
		padding-left: 16px;
	}

	.add-user-alt {
		padding-left: 5px;
	}

	.number-wrapper {
		padding-left: 10px;
		padding-right: 12px;
	}

	.number {
		color: #fff;
	}

	.section {
		margin-bottom: 16px;
	}

	.buttons {
		padding-top: 2rem;
		padding-left: 56px;
	}
}
