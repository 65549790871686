.FilterPanel {
   display: flex;
   height: 100%;

   .main {
      background: #f1f5fb;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0 8px 48px;
   }
   
   .head {
      background: #f1f5fb;
      padding: 0 16px 8px;
      position: sticky;
      top: 0;
      z-index: 1;
   
      .filter-type-label {
          align-items: center;
          color: var(--navy-blue);
          display: flex;
          font-size: 16px;
          font-weight: 700;
          height: 88px;
      }
   
      .search {
          height: 40px;
   
          .search-input {
              background: #e1e9f5;
              border: 0;
              border-radius: 3px;
              box-sizing: border-box;
              font-size: 14px;
              height: 100%;
              padding: 0 0 0 16px;
              width: 100%;
          }
      }
   }
   
   .content {
      flex: 1;
   
      .virtual-scroll {
          height: 100%;
          width: 100%;
   
          &.hidden {
              display: none;
          }
      }
   }
   
   .item {
      align-items: center;
      display: flex;
      font-size: 14px;
      height: 32px;
      padding: 0 16px;
      position: relative;
      user-select: none;
   }
   
   .item.header {
      font-weight: 900;
      top: -16px;
   }
   
   .item.link {
      border-radius: 3px;
      cursor: pointer;
   
      &:hover {
          background: #fff;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
   
          .count {
              color: var(--link);
          }
      }
   
      .label {
          max-width: 85%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }
   
      .count {
          color: #999;
          font-size: 11px;
          position: absolute;
          right: 16px;
      }
   }
   
   .no-match {
      align-items: center;
      display: flex;
      height: 32px;
      padding: 0 16px;
   }
   
   .char-index {
      background: #e6ebf3;
      position: relative;
      width: 32px;
   
      &:hover {
          .character-wrap {
              width: 48px;
          }
      }
   
      @media (max-width: 767px) {
          width: 48px;
      }
   }
   
   .character-wrap {
      background: #9babbb;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      position: absolute;
      right: 0;
      transition: width 0.3s;
      width: 100%;
   
      &::-webkit-scrollbar {
          width: 4px;
      }
   
      &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.2);
      }
   
      &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.4);
          border-radius: 2px;
      }
   
      &::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.6);
      }
   }
   
   .character {
      align-items: center;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      color: #fff;
      cursor: pointer;
      display: flex;
      flex: 1;
      font-size: 11px;
      font-weight: 600;
      justify-content: center;
      min-height: 24px;
      user-select: none;
   
      &:hover:not(.none-found) {
          background: rgba(0, 0, 0, 0.2);
          font-size: 20px;
      }
   
      &.none-found {
          //background: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.2);
      }
   }
   
}


