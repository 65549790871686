.FieldToolBox {
	background-color: #455060;
	height: 100%;
	padding-top: 12px;

	.heading-toolbox {
		color: white;
		padding-left: 12px;
		font-size: 20px;
	}

	.heading-drag {
		color: lightgray;
		font-size: 12px;
		padding-left: 4px;
	}

	.tools {
		padding-top: 0px;
	}

	.tool:hover {
		background-color: #728299;
	}

	.tool-icon {
		color: white;
	}

	.tool-text {
		color: white;
	}

	.tool-icon:hover {
		color: white;
	}
}
