.assignment-editing-popover {
	z-index: 10000;

	.popover-inner {
		background-color: white;

		.canvas {
			//padding: 20px;
		}

		.where-selection {
			padding: 20px;
			h3 {
				margin: 0;
			}
		}

		.location-selection {
			padding: 20px;
			min-width: 500px;
		}

		.tag-selection {
			padding: 20px;
			min-width: 500px;
		}
		/*
		.date-selection {

			background-color: white;

			.DayOfWeekPeriodSelector {
				.canvas {
					padding: 20px;
				}
			}

			.DateTimePeriodSelector {
				.canvas {
					padding: 10px;
				}
			}

			.RangePeriodSelector {
				.canvas {
					padding: 10px;
				}
			}
		}
		*/
	}
}

.LocationAssigner {
	display: inline-block;
	//border: 1px solid lightgray;

	.assign-control {
		display: flex;
		flex-direction: column;

		.editable-caption-row {
			//background-color: yellow;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}

		label {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.54);
		}

		.filter-link {
			display: inline-block;
			cursor: pointer;
		}
	}
}
