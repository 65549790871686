.PeopleSelector {
	display: block;
	//max-width: 500px;
	//min-width: 460px;
	min-width: 360px;
}

/*
.MuiAutocomplete-option[data-focus='true'] {
	background-color: rgba(51, 138, 190, 0.24) !important;
	//color: white;
	opacity: 0.9;
}
*/
