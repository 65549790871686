.StaffDetailsUpdater {
	.MuiDialog-paper {
		width: 30rem;
		overflow-y: visible;
	}

	.actions {
		padding-right: 18px;
		padding-bottom: 12px;
		padding-top: 20px;
	}
	.or-btn {
		padding-left: 8px;
		margin-right: -12px;
	}

	.divider {
		margin: 10px;
		//background-color: red;
	}

	.TeamSelector {
		padding-top: 20px;
	}
}
