.DuePeriodPicker {
	display: block;

	.DateSelector {
		margin-right: 4px;
	}
	.date-period-picker-row {
		width: fit-content;
		display: flex;
		flex-direction: row;
	}
	.TimeSlotPicker {
		margin-right: 4px;
	}

	.caption {		
		padding-right: 4px;
	}
}
