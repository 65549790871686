.task-startingdate-selector-popover {
	z-index: 10000;
}

.date-selector-popover {
	max-width: 365px;
	z-index: 10000;

	.popover-inner {
		.popover-body {
			padding: 0px;

			.timeframes {
				padding-top: 50px;
			}
			.timeframe-btn {
				//border-bottom: 1px dotted #477b96;
				//color: #0047b3;
				//text-align: left;
				display: block;
			}
		}
	}
}

.DateSelector {
	display: block;

	.date-control {
		display: flex;

		&.dateColumn {
			flex-direction: column;

			label {
				font-size: 12px;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&.dateRow {
			flex-direction: row;
			align-items: center;

			label {
				font-size: 12px;
				color: rgba(0, 0, 0, 0.54);
				margin-right: 6px;
				margin-top: 2px;
			}
		}
	}
	.filter-link {
		display: inline-block;
	}
}
