.Section {
	width: 260px;
	margin: 0.5em;
	height: 260px;


	.section-card {
		//background-color: lightblue;;
		width: 100%;

		height: 100%;

	}

	.SectionHeader {
		margin-bottom: 0;
		position: relative;
		//background-color: green;

		//display: flex;
		//flex-direction: row;
		//align-items: flex-end;

		.wrapper {
			//padding-top: 24px;
			margin-left: 1em;
			margin-right: 1em;
			margin-bottom: 0px;
			flex: 1;

			&.bar {

				border-bottom: 1px solid #d9d9d9;;
				//margin-top: 1em;;
				padding-bottom: 0.8em;
			}
		}

		.contents {
			//background-color: red;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;;
		}

		.actions {
			//background-color: purple;
			//height: 60px;
			//float: right;
			//margin-top: 1em;
			//margin-right: 0.25em;
			//overflow: visible;
			position: absolute;
			top: -0.5em;
    	right: 0em;

			.speed-dial {


				.MuiSpeedDialAction-staticTooltipLabel {
					//background-color: #1b6ac9;
					background-color: #3187b2;
					white-space: nowrap;
					color: #fff;
					//width: 200px;

				}
			}
		}

		//background-color: #f1f5f9;
		//border-bottom: 1px solid rgba(0, 0, 0, 0.125);


		.title {
			font-size: 1.01rem;
			margin-bottom: 0;
			margin-top: 1em;
		}


	}

	.SectionContent {
		margin: 0;
		height: 80%;
		padding-top: 0px;
		//overflow-y: scroll;


	}

	.SectionPlaceholder {
		//background-color: red;
		padding-top: 10px;
		width: 100%;
		min-height: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		.IconAvatar {
			margin: 10px;;
		}

		.text {
			text-align: center;
			color: grey;
		}
	}
}

