.ToggleButton {
	.rounded {
		border-radius: 30px;
		min-width: 46px;
	}

	.MuiToggleButton-root {
		&.Mui-selected {
			background-color: color('primary', 'main');
			color: white !important;
		}

		&.Mui-selected:hover {
			background-color: color('primary', 'main');
		}
	}
}


.MuiButtonBase-root {
	&.SelectionButton {

	display: block;
	padding: 0.4rem 0.6rem;
	position: relative;
	//padding-left: .5rem;
	border-radius: 4rem;
	text-align: left;	


	&:hover {
		background: #FAF7F5;
		box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
		border-radius: 4rem;
		//padding: 0.5rem 0.5rem;
	}

	&.selected {
    background: #fff;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
	 border-radius: 4rem;
	}

}
}
