.StaffListItem {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	z-index: unset;

	&.module-list-item:hover {
		cursor: default;
	}

	&.module-list-item {
		&.editing {
			background-color: #f6f2ef;
			border-bottom: 1px solid #e5e5e5;
		}

		&.errored {
			border: 1px solid red;
		}
	}

	.staffMemberNameEditor {
		//background-color: red;
		width: 50%;
	}

	.module-list-icon {
		min-width: 50px;
	}

	.staffMemberTitle {
		.title {
			//background-color: yellow;
			flex: 1;
		}
	}

	.LocationAssigner {
		//background-color: red;
		//justify-self: flex-end;
		//flex: 1;
		margin: 6px;
	}

	.TeamPopoutSelector {
		//background-color: red;
		//justify-self: flex-end;
		//flex: 1;
		margin: 6px;
	}

	.PositionPopoutSelector {
		//background-color: red;
		//justify-self: flex-end;
		//flex: 1;
		margin: 6px;
	}

	.module-list-info {
		max-width: unset;
	}

	.module-list-buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.address {
		color: #6c757d;
		font-size: 13px;
	}
	.user-accesslevel {
		color: #6c757d;
		font-size: 13px;
	}
}
