.PositionAdder {
	.MuiDialog-paper {
		width: 30rem;
		max-height: 50%;
		overflow: initial;
	}

	.team-selection {
		padding-left: 10px;
		padding-right: 10px;
	}

	.positionItem {
		&.editing {
			background-color: #f6f2ef;
			border-bottom: 1px solid #e5e5e5;
		}

		&.errored {
			border: 1px solid red;
		}
	}

	.btn-add_position {
		margin-left: 0.6em;
	}
}
