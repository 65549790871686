.demo-filter-popover {
	min-width: 340px;

	.popover-inner {
		min-width: 340px;
	}
}

.MediaPage {
	display: block;

	.profile-pic {
		margin-right: 10px;
	}
}
