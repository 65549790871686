.RoutineViewer {
	//display: block;
	//flex: 1;
	//background-color: yellow;


	margin: 20px;
	position: relative;
	//max-width: 680px;
	//width: 94%;
	//margin: 12px auto;

	.section {
		margin-bottom: 16px;
		padding-right: 60px;

		.contents {
			width: 100%;
		}
	}

	.bullet-point {		
		font-weight: 500;
		font-size: 1.3rem;
	}

	.TaskTypeSelector {
		width: 50%;
		margin-top: 0px;
	}

	.number-wrapper {
		padding-left: 10px;
		padding-right: 12px;
	}

	.number {
		color: #fff;
	}

	.ContentDivider {
		margin-top: unset;
	}



}