.LoadingBar {
	display:block;

	//background-color: red;
	min-height: 36px;

	.dot-pulse {
		//background-color: yellow;
		margin-left: 16px;
		margin-top: 16px
	}


}