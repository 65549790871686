.OptionRulesEditor {
  .MuiAccordionSummary-content {
   //border: 1px dotted red;
   margin: 0px;
  } 

  .MuiAccordionSummary-content.Mui-expanded {
   margin: 2px 0; 
  }

  .MuiAccordionSummary-root.Mui-expanded {
   min-height: 48px;
  } 
   
}


