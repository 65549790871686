.LocationLocaleSetter {
	.MuiDialog-paper {
		width: 30rem;
	}

	.actions {
		padding-right: 18px;
		padding-bottom: 12px;
	}
	.or-btn {
		padding-left: 8px;
		margin-right: -12px;
	}
}
