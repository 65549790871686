.LoadingBox {
	display: block;
	position: relative;
	min-height: 110px;

	//background-color: red;
	//min-height: 36px;

	.overlay {
		background-color: white;
		opacity: 0.7;
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 999;
	}

	.indicator-container {
		position: absolute;
		z-index: 1000;
	}

	.indicator-wrapper {
		height: 110px;
		width: 180px;
		z-index: 1010;
	}

	.indicator {
		//background-color: white;
		//background-color: #3187b2;
		//color: #455a64; //background-color: #f6f2ef;
		//background-color: #f6f2ef;
		//background-color: #fffadf;
		//color: #7d3006;
		//background-color: black;
		color: #7d3006;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		//border-radius: 25px;
	}

	.dot-pulse {
		margin-top: 8px;
	}
}
