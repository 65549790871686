



.UserProfileEditor {

	display:block;
	//background-color: red;

	.profile-box {

		position: relative;
		overflow: unset;

		.avatar-banner {
			position: absolute;
			top: -60px;
			width: 100%;
			//margin-left: -20px;
			height: auto;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;


			/*
			.message {

				color: white;
				background-color: darkgray;
				border-radius: 8px;
				padding-top: 4px;
				padding-bottom: 4px;
				padding-left: 8px;
				padding-right: 8px;
			}
			*/

		}

		.card-body {
			margin-top: 40px;
		}

		.avatar-actions {
			//background-color: red;
			padding-top: 60px;
			padding-bottom: 8px;

			.profile-picture-btn {
				margin-bottom: 8px;
				//background-color: red;
			}
		}



		.buttons {
			padding-top: 16px;
		}
	}

}