.LocationAddressEditor {
	display: block;

	.cantMap {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		.warning {
			color: orange;
		}
	}

	.locate-options {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.relocating-progress {
		margin-left: 10px;
	}
}
