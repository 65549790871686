.OperationPage {
	//max-width: 700px;

	h2 {
		margin: 0px;
		font-weight: initial;
	}

	.chip-setup {
		margin: 8px;
		color: white;
		//height: unset;
		background-color: red;
	}

	.buttons {
		margin-top: 10px;
	}

	.back-btn {
		margin-right: 16px;
	}

	.form-buttons {
		margin-left: 8em;
		margin-right: 8em;
	}

	.indent-actions {
		margin-left: 8em;
		margin-right: 8em;
	}

	.content {
		display: flex;
		position: relative;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;		
	}

	.Page::before {
		content: "";
		display: block;
		position: absolute;
		background-repeat: no-repeat;
		height: 87.3%;
		width: 51rem;
		border-radius: 100vh 100vh 0 0;
		z-index: 0;		
		background-image: radial-gradient(circle at top right, #e942ff -20%, #8838ff);
		bottom: 0;
		right: -28.8%;
	 }
	 
	 @media only screen and (max-width: 800px) {
		.Page::before {
		  right: -32.2rem;
		  top: 43rem;
		}
	 }

}

