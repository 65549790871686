.FlagSelector {

	display: inline-block;

	.select-flag {

		min-width: 14em;
	}

	.IssueFlag {
		margin-top: 16px;
    margin-bottom: 8px;
	}

}