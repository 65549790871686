.FileResourceList {
	.MuiDialog-paper {
		width: 100%;
		min-width: 40%;
		max-height: 40%;
	}

	.list-title {
		cursor: pointer;
	}

	.optionItem {
		padding-right: 120px;

		&.editing {
			background-color: #f6f2ef;
			border-bottom: 1px solid #e5e5e5;
		}

		&.errored {
			border: 1px solid red;
		}
	}

	.error {
		color: red;
	}

	.btn-add_option {
		margin-left: 0.6em;
	}

	.item-divider {
		margin-top: 8px;
		margin-bottom: 0px;
	}

	.or-btn {
		padding-left: 14px;
	}

	.MuiDialogActions-spacing > :not(:first-child) {
		margin-left: unset;
	}

	.nameEditorSection {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		width: 50%;
	}

	.attachmentSection {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: flex-start;
	}

	.resource-name {
		width: 60%;
		padding: 6px;
	}
}
