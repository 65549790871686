.MetricsPage {
	.PageHeader {		

		.filter-btn {			
			margin-right: 12px;
		}		
	}

	.SearchBox {
		border-radius: 0;
		width: 100%;

		.MuiOutlinedInput-root {
			border-radius: 8px;
		}
		
		.search-input {
			min-width: 400px;
		
		}
	}	

	.TaskPeriodSection {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		padding: 8px;				
	}
}



