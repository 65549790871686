.routine-occurrence-editing-popover {
	z-index: 10000;

	.popover-inner {
		min-width: 260px;

		.OccurrenceSelector {
			width: 100%;

			.dropdown {
				width: 100%;
			}
		}

		.actions {
			//background-color: red;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
		}

		.until-divider {
			//background-color: red;
			margin-top: 8px;
			margin-bottom: 8px;
		}

		.until-section {
			//padding: 8px;
			//width: 50%;
			//background-color: red;
		}

		.past-date {
			.filter-link {
				border-bottom: unset;
				cursor: pointer;
				text-decoration: underline;
				color: orange;
			}
		}

		.filter-link {
			border-bottom: unset;
			cursor: pointer;
			text-decoration: underline;
		}

		.select-cadence {
			//background-color: red;
			margin-top: unset;
			margin-bottom: unset;
		}
	}

	.popover-body {
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 16px;
		padding-right: 16px;
	}
}

.RoutineOccurrencePicker {
	display: block;

	.picker-control {
		display: flex;
		flex-direction: column;

		.editable-caption-row {
			//background-color: yellow;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			font-size: 16px;
		}

		label {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.54);
		}

		.filter-link {
			display: inline-block;
			cursor: pointer;
		}
	}
}
