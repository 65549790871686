

.SweetAlert {

	display: block;
	z-index: 10000 !important;

	.button {
		text-transform: none !important;
	}

	.success {
		background-color: #43a047;
  }

	.error {
    background-color: #d32f2f;
	}

  .info {
    background-color: #303f9f;
	}

	.danger {
		background-color: #f44336;
		color: white
	}

  .warning {
    background-color: #ffa000;
	}

}