.TopNav {
	flex: 1;

	.toolbar {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;

		.toolbar-left {
			margin-left: 0px;
			min-width: 50%;
		}

		.toolbar-right {
			margin-left: auto;
		}

		.menu-button {
			text-transform: none !important;
			//color: whitesmoke;
			margin-left: 20px;
			margin-top: -2px;
		}

		.text {
			margin: 2px;
		}
	}
}

@media only screen and (max-width: 700px) {
	/* For mobile phones: */
	[class*='TopNav'] {
		.menu-button {
			//color: whitesmoke;
			span.text {
				display: none;
			}
		}
	}
}

@media only screen and (max-width: 320px) {
	/* For mobile phones: */
	[class*='TopNav'] {
		.menu-button {
			//color: whitesmoke;
			min-width: 40px;
		}
	}
}
