.DaysOfWeekPicker {
	background-color: #fff;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 8px;
	padding-right: 2px;
	max-width: 250px;
	.MuiToggleButtonGroup-root {
		.ToggleButton {
			margin-right: 4px;
			margin-bottom: 4px;
			width: 30px;
			height: 30px;
		}

		.rounded {
			min-width: unset;
		}
	}
}
