.snacktime {
	//background-color: red;
	top: 32px !important;
	//top: 46px !important;
}

.feedback-content {
	//background-color: black !important;
	padding-top: 2px !important;
	padding-bottom: 2px !important;
	border-radius: 50px !important;
	flex-wrap: nowrap !important;

	.MuiSnackbarContent-message {
		//background-color: red;
		padding: 0px;
	}

	.MuiSnackbarContent-action {
		padding: 0px;

		.MuiIconButton-root {
			padding: 6px;
		}

		.close-icon {
			background-color: red;
		}
	}
}
