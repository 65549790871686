
.DateFieldFilter {

	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;

	padding-left: 8px;


	.DateSelector {
		margin-left: 8px;
		min-width: 170px;
	}

	.PriorDateSelector {
		margin-left: 8px;
		min-width: 170px;
	}

	.delete {
		margin-left: auto;
	}

	.ico {
		display: inline-block;
		font-size: 20px;
		position: relative;
		top: 4px;
	}
}

.DateFieldFilter:hover {
	background-color: #EDEDED;
}