.RoutineEditor {
	display: block;

	.header-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-right: 10px;
		min-height: 60px;
	}

	.scoring-section {
		
		flex-direction: row;
		display: flex;
		padding: 10px;


		.block {
			width: 50%;
			display: flex;
			flex-direction: column;
			min-height: 160px;
			align-items: flex-start;
		}
	}

	.btn-clearform {		
		//margin-right: 20px;
	}

	.select-timezone {
		width: 50%;
	}

	.select-status {
		width: 20%;
	}

	.pass-threshold-percent {
		width: 100px;		
		margin-left: 50px;
	}

	.ZonedDatePicker {
		margin-top: 1em;
	}

	.ContentDivider {
		margin-top: 0px;
		span {
			font-size: 16px !important;
		}
	}

	.what-divider {
		margin-left: 1em;
		margin-right: 1em;
	}

	.tabs-bar {
		&.wide {
			margin-left: 8em;
			margin-right: 8em;
		}

		.tab {
			//background-color: red;
			text-transform: none;
			min-width: 100px;
		}

		.MuiTabs-flexContainer {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			flex-direction: row;
		}
	}

	.wizard-step {
		min-height: 160px;
	}
}
