
.UserGroupFilter {

	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: baseline;

	padding-left: 8px;

	.filter-with-tag {
		margin-left: 8px;
		min-width: 170px;
	}

	.filter-with-chip {
		margin-left: 8px;
	}

	.delete {
		margin-left: auto;
	}

	.ico {
		display: inline-block;
		font-size: 20px;
		position: relative;
		top: 4px;
	}
}

.UserGroupFilter:hover {
	background-color: #EDEDED;
}

