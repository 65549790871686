.StaffActionBar {
	display: block;
	position: relative;

	.bg-primary-diabled {
		background-color: lightgray;
	}

	.LocationAssigner {
		margin: 10px;
		min-width: 200px;
	}

	.actions {
		padding-left: 54px;
	}

	.action-panel {
		padding: 8px;
		background-color: #f4efeb;
		z-index: 1;
		width: 100%;
	}

	.suggestion-box {
		position: absolute;
		z-index: 2;
		min-width: 200px;
	}

	.delete-btn {
		background-color: orange !important;
	}
}
