.OperationEdit {
	//max-width: 700px;
	.PageHeader {		
		padding-bottom: 24px;
	}

	h2 {
		margin: 0px;
		font-weight: initial;
	}

	.buttons {
		margin-top: 10px;
	}

	.indent-actions {
		margin-left: 8em;
		margin-right: 8em;
	}
}
