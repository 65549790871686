.ListEditor {
	.MuiDialog-paper {
		width: 30rem;
		max-height: 40%;
	}

	.list-title {
		cursor: pointer;
	}

	.optionItem {
		&.editing {
			background-color: #f6f2ef;
			border-bottom: 1px solid #e5e5e5;
		}

		&.errored {
			border: 1px solid red;
		}
	}

	.btn-add_option {
		margin-left: 0.6em;
	}

	.item-divider {
		margin-top: 8px;
		margin-bottom: 0px;
	}

	.or-btn {
		padding-left: 14px;
	}

	.MuiDialogActions-spacing > :not(:first-child) {
		margin-left: unset;
	}
}
