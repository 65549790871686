.UserNav {

	//min-width: 10%;
	//background-color: red;


	.user-avatar {
		cursor: pointer;
	}


}