.task-group-by {
   .grouping-btn {
      font-size: 13px;
      // padding-block-start: 0.425em;
      // padding-block-end: 0.425em;
      //margin-right: 12px;

      display: inline-block;
      padding: 0.3em 1em;
      border-radius: 99rem;
      vertical-align: middle;
      text-decoration: none;
      text-align: center;
      white-space: normal;
      cursor: pointer;
      outline-offset: 0.4em;
      max-inline-size: 100%;
      background-color: white;
      border: 1px solid #DEDEDE;
      line-height: 1.5;
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
      -webkit-appearance: none;
      -moz-appearance: none;
      box-sizing: border-box;
   }



   button {
      all: unset;
    }
    
    .DropdownMenuContent,
    .DropdownMenuSubContent {
      min-width: 120px;
      background-color: white;
      border-radius: 6px;
      padding: 5px;
      box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
      animation-duration: 400ms;
      animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
      will-change: transform, opacity;
    }
    
    .DropdownMenuContent[data-side='top'],
    .DropdownMenuSubContent[data-side='top'] {
      animation-name: slideDownAndFade;
    }
    
    .DropdownMenuContent[data-side='right'],
    .DropdownMenuSubContent[data-side='right'] {
      animation-name: slideLeftAndFade;
    }
    
    .DropdownMenuContent[data-side='bottom'],
    .DropdownMenuSubContent[data-side='bottom'] {
      animation-name: slideUpAndFade;
    }
    
    .DropdownMenuContent[data-side='left'],
    .DropdownMenuSubContent[data-side='left'] {
      animation-name: slideRightAndFade;
    }
    
    .DropdownMenuItem,
    .DropdownMenuCheckboxItem,
    .DropdownMenuRadioItem,
    .DropdownMenuSubTrigger {
      font-size: 13px;
      line-height: 1;
      color: #3187b2;
      //color: var(--violet-11);
    
      border-radius: 3px;
      display: flex;
      align-items: center;
      height: 25px;
      padding: 0 5px;
      position: relative;
      padding-left: 25px;
      user-select: none;
      outline: none;
    }
    
    .DropdownMenuSubTrigger[data-state='open'] {
      //background-color: var(--violet-4);
      //color: var(--violet-11);
    }
    
    .DropdownMenuItem[data-disabled],
    .DropdownMenuCheckboxItem[data-disabled],
    .DropdownMenuRadioItem[data-disabled],
    .DropdownMenuSubTrigger[data-disabled] {
      color: var(--mauve-8);
      pointer-events: none;
    }
    
    .DropdownMenuItem[data-highlighted],
    .DropdownMenuCheckboxItem[data-highlighted],
    .DropdownMenuRadioItem[data-highlighted],
    .DropdownMenuSubTrigger[data-highlighted] {
      //background-color: var(--violet-9);
      background-color:#3187b2;
      color: white;
      //color: var(--violet-1);
    }
    
    .DropdownMenuLabel {
      padding-left: 25px;
      font-size: 12px;
      line-height: 25px;
      color: var(--mauve-11);
    }
    
    .DropdownMenuSeparator {
      height: 1px;
      background-color: lightslategray;
      margin: 5px;
    }
    
    .DropdownMenuItemIndicator {
      position: absolute;
      left: 0;
      width: 25px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    
    .DropdownMenuArrow {
      fill: white;
    }
    
    .IconButton {
      font-family: inherit;
      border-radius: 100%;
      height: 35px;
      width: 35px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      //color: var(--violet-11);
      color: yellow;
      background-color: white;
      box-shadow: 0 2px 10px var(--black-a7);
    }
    
    .IconButton:hover {
      //background-color: var(--violet-3);
      background-color: #87B231;
      
    }
    
    .IconButton:focus {
      box-shadow: 0 0 0 2px black;
    }
    
    .RightSlot {
      margin-left: auto;
      padding-left: 20px;
      color: var(--mauve-11);
    }
    
    [data-highlighted]>.RightSlot {
      color: white;
    }
    
    [data-disabled] .RightSlot {
      color: var(--mauve-8);
    }
    
    @keyframes slideUpAndFade {
      from {
        opacity: 0;
        transform: translateY(2px);
      }
    
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    
    @keyframes slideRightAndFade {
      from {
        opacity: 0;
        transform: translateX(-2px);
      }
    
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
    
    @keyframes slideDownAndFade {
      from {
        opacity: 0;
        transform: translateY(-2px);
      }
    
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    
    @keyframes slideLeftAndFade {
      from {
        opacity: 0;
        transform: translateX(2px);
      }
    
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
}