.Page {
	//display: block;	
	position: relative;
	z-index: 1;
	//flex:1;
	//min-height: 1000px;
	//min-height: calc(100vh - #{$toolbar-height});
	min-height: 100vh;
	
	display: flex;
	flex-direction: column;

	.PageListFooter {
		.pagination {
			position: absolute;
			 bottom: 0px;
			 right: 30px;
		}

		.page-display {
			position: absolute;
		 bottom: 20px;
		 left: 0;
		 right: 0;
		 margin: 0 auto;
		 width: fit-content; 
		}
	}

	.PageHeader {
		
		margin-bottom: 0px;
		position: relative;

		&.clipTop {
			margin-top: -20px;
		}
		//background-color: yellow;

		//display: flex;
		//flex-direction: row;
		//align-items: flex-end;

		.wrapper {
			padding-top: 6px;
			padding-left: 4em;
			padding-right: 4em;
			margin-bottom: 0px;			
			flex: 1;

			&.bar {
				//background-color: red;
				border-bottom: 1px solid lightgray;
				margin-top: 1em;
				padding-bottom: 0.8em;
			}

			&.bottom-bar {
				padding-bottom: 0em !important;
			}

			.titlebar {
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				//padding-left: 15px;
				//padding-right: 15px;
				//background-color: green;;

				.buttons-left {
					//margin-left: 0px;
					//margin-left: auto;
					min-width: 180px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-end;
					//padding-bottom: 0.4em;
					//background-color: red;;
				}

				.buttons-right {
					min-width: 180px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-end;
					//padding-bottom: 0.4em;
				}

				.btn {
					margin-bottom: 0.2em;
					margin-left: 1em;
					border-radius: 25px;

					.ico {
						margin-right: 8px;
						font-size: 20px;
					}
				}
			}

			.bottombar {
				//background-color: red;
				width: 100%;
				//display: flex;
				//flex-flow: row nowrap;
				//justify-content: flex-start;
			}
		}

		.contents {
			//background-color: red;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			//background-color: khaki;
		}

		.actions {
			//background-color: purple;
			//height: 60px;
			//float: right;
			//margin-top: 1em;
			//margin-right: 0.25em;
			//overflow: visible;
			display: flex;
			flex-direction: row;
			position: absolute;
			top: 1em;
			right: 1em;
			z-index: 10000;

			.actions-left {
				padding-right: 8px;
			}
		}

		//background-color: #f1f5f9;
		//border-bottom: 1px solid rgba(0, 0, 0, 0.125);

		.title {
			font-size: 2rem;
			margin-bottom: 0;
		}

		.subtitle {
			// font-size: 1rem;
			// font-weight: normal;
			// margin-bottom: 0;
			// margin-top: 0;
			font-weight: normal;
			margin: 0.4rem auto 0 auto;
			max-inline-size: 80rem;
			word-wrap: break-word;
		}
	}

	.PageContent {
		min-height: 300px;
		//background-color: yellow;
		padding: 8px;

		&.narrow {
			margin-left: 1em;
			margin-right: 1em;
		}

		&.normal {
			//background-color: blue;
			margin-left: 4em;
			margin-right: 4em;
		}

		&.wide {
			//background-color: green;
			margin-left: 8em;
			margin-right: 8em;
		}

		.section-grid {
			//background-color: yellow;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.PageActions {
		&.buttons {
			margin-top: 2em;
			margin-bottom: 1em;
		}
	}
}
