.FilterChips {
    align-items: center;    
    cursor: pointer;
    display: inline-flex;    
    margin-left: 24px;
    margin-bottom: 16px;
    max-width: 100%;
    user-select: none;
    
    .filter-chip {
        margin-right: 6px;
    }
}
