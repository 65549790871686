.DataSourceListEditor {

	.MuiDialog-paper {
		min-width: 30rem;
		max-height: 80%;
	}

	//background-color: white;	
	padding: 8px;
	//min-height: 300px;	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	//max-height: 300px;	

	.header {
		
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding-left: 0px;
		padding-right: 0px;
		

		.name {
			
			
			font-size: 1.40rem;
			font-family: "Roboto", "Helvetica", "Arial", sans-serif;
			font-weight: 500;
			line-height: 1.6;
			letter-spacing: 0.0075em;						
		}
		.spacer {
			//background-color: blue;
			flex: 1
		}
		.ButtonMenu {
			//background-color: cyan;
			flex:none;
			
		}
		
		.nameEditorSection {
			display: flex;
			flex-flow: row nowrap;
			//align-self: flex-start;
			//justify-self: flex-start;
			//justify-self: flex-start;
			
			justify-content: flex-start;
			align-items: center;
			width: 70%;
		}
	}

	.item-header-divider {
		margin-top: 8px;
		margin-bottom: 0px;
		//align-self: flex-start;
		//background-color: yellow;
		width: 100%;
		padding-left: 8px;
		padding-right: 8px;
	}

	.new-partition {
		
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
		

		.LocationAssigner {
			padding: 10px;
			font-size: 16px;
		}
		//flex: 1;
		//display: flex;
		// flex-direction: column;
		// justify-content: center;
		// align-items: center;
		// //min-height: 200px;
		// min-width: 100%;

		
	}

	.new-assignment-title{ 
		//background-color: cyan;
		font-size: 16px;
		padding: 4px;
		//height: 60px;
	}

	.content {		


		
		min-height: 200px;
		min-width: 400px;	
		
		display: flex;
      flex-direction: column;
		align-items: center;
		justify-content: flex-start;
      height: 100%;
		width: 100%;
		flex: 1;


	}

	.buttons {
		display: flex;
      flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		//background-color: cyan;
		width: 100%;
		padding-left: 12px;
		padding-right: 12px;
		padding-bottom: 8px;
		min-height: 60;
	}

	.BasicListEditor {
		background-color: white;
		width: 100%;
	}

	.tab {
		margin-top: 0px;					
		text-transform: none;		
		max-width: 160px;
		font-size: 14px;
	}

	.single-tab {
		font-size: 14px;
		margin-top: 0px;					
		text-transform: none;		
	}

	.tabs-bar {				
		overflow: hidden;
		width: 100%;
		padding-bottom: 6px;

	}


	
}
