.IconAvatar {


	display: inline-block;

	.niniature {
		font-size: 0.8em;
	}

	.tiny {
		font-size: 1em;
		//font-weight: 700;
	}

	.thumbnail {
		//font-size: 1.6em;
		font-size: 1.4em;
    font-weight: 700;
	}

	.normal {
		//font-size: 3em;
		font-size: 2.8em;
		//font-weight: 700;
	}

	.large {
		font-size: 4.2em;
    //font-weight: 700;
	}

	.thicken {
		font-weight: 700;
	}


}

.UserAvatar {
	display: block;

	&.miniature {
		.initials {
			font-size: 10px;
			font-weight: 600;
		}
	}

	&.tiny {
		.initials {
			font-size: 12px;
			font-weight: 700;
		}
	}

	&.thumbnail {
		.initials {
			font-size: 16px;
			font-weight: 500;
		}
	}

	&.normal {
		.initials {
			font-size: 24px;
			font-weight: 700;
		}

	}

	&.large {

		.initials {
			font-size: 3em;
		}
	}


	&.crew-avatar {

		&.tiny {

			.initials {
				margin-top: -8px;
				line-height: 1.6;
			}
		}

		&.thumbnail {

			.initials {
				margin-top: -10px;
				line-height: 1.8;
			}
		}

		&.normal {

			.initials {
				margin-top: -10px;
				line-height: 1.8;
			}
		}

		&.large {

			.initials {
				margin-top: -20px;
				line-height: 1.6;
			}
		}


	}


}