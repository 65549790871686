

.TaskMetricsHeatMap {
   // background-color: 'yellow';

   // display: flex;
   // flex-direction: column;
   // align-items: center;
   // justify-items: center;
   display: flex;
   flex-direction: row;
   width: 100%;
   justify-content: center;
   padding-top: 8px;
   padding-bottom: 16px;
   
}