



.UserAdder {

	.paper {
		background-color: red;
	}

	.add-user {

		padding-right: 5px;

	}

	h2 {
		margin: 0px;
		font-weight: initial;
	}

	.card-header {
		background-color: #f1f5f9;
	}

	.card-text {
		margin-top: 0px;
		margin-bottom: 1rem;
	}


	.loginType {

		margin-left: -16px;


	}

	.or {

		padding: 5px;
		font-weight: lighter;
	}

	.or-btn {

		padding-left: 16px;
	}

	.add-user-alt {
		padding-left: 5px;
	}

	.number {
		color: #fff;
	}

	.section {
		margin-bottom: 16px;
	}

}