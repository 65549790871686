.demo-filter-popover {
	min-width: 340px;

	.popover-inner {
		min-width: 340px;
	}
}

.PeriodSelectionBar {
	
	.SelectionButtons {
		.MuiToggleButtonGroup-root {
			.ToggleButton {
				&:first-child {
					.MuiToggleButton-root {
						border-top-left-radius: 4px;
						border-bottom-left-radius: 4px;
					}
				}

				&:last-child {
					.MuiToggleButton-root {
						border-top-right-radius: 4px;
						border-bottom-right-radius: 4px;
					}
				}

				.MuiToggleButton-root {
					border-radius: 0;
					border: 1px solid rgba(0, 0, 0, 0.12);
					margin: 0;
					padding: 6px 12px;
					text-transform: none;
					font-size: 14px;

					&.Mui-selected {
						background-color: color('primary', 'main');
						color: white;

						&:hover {
							background-color: color('primary', 'dark');
						}
					}

					&:hover {
						background-color: rgba(0, 0, 0, 0.04);
					}
				}
			}
		}
	}
}



.AnnualSelectedMonthDatesPicker {
	background-color: #fff;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 8px;
	padding-right: 4px;
	max-width: 250px;

	.MuiToggleButtonGroup-root {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;

		.ToggleButton {
			margin-right: 2px;

			.day-btn {
				margin-top: 6px;
				min-width: unset;
				width: 30px;
				height: 30px;
			}

			.last-btn {
				width: 128px;
				margin-top: 6px;
				height: 30px;
				margin-bottom: 10px;
			}

			.month-btn {
				margin-top: 6px;
				width: 35px;
				height: 30px;
			}

			.rounded {
				min-width: unset;
			}
		}
	}
}

.GeneralPage {
	display: block;

	.LocationSublocationSelector {
		//max-width: 50%;
	}

	.menu-button--selected {
		background: #fff;
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
	}

	.menu-button {
		display: block;
		padding: 0.5rem 0.5rem;
		position: relative;
		padding-left: 0.5rem;
		border-radius: 4rem;
		text-align: left;

		&:hover {
			background: #faf7f5;
			box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
		}
	}
}


@import './DropdownMenuDemo/style.scss';
