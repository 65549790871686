.color-selector-popover {
	z-index: 10000;

	// .popover-inner {
	// 	//background-color: white;
	// 	//border: 1px solid red;

	// 	//padding: 20px;
	// 	//min-width: 500px;
	// }

	.buttons {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: -36px;
	}
}

.ColorDot {	
	display: inline-block;

	
}