
.FieldList {




	.col-letter {
		max-width: 60px;

		.letter {
			margin: 8px;
		}
	}

	.col-fields {

		margin-top: 4px;

		.field-chip {
			margin: 8px;
		}
	}
}

