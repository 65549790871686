.RoutinesSubPage {
	.PageHeader {
		//background-color: red;
		//padding-bottom: 20px;
	}

	.empty-arrow {
		position: absolute;
		top: 40px;
		left: 40px;
		z-index: 2;
	}

	.ResponseListHeader {
		padding-top: 6px;
		margin-left: 20px;
		margin-right: 20px;
	}

	.filter-box {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ResponseFilter {
		padding-top: 20px;
		margin-bottom: 22px;
		height: 36px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}
