.TaskList {
	display: block;
	min-height: 400px;

	.pagination {		
		position: absolute;
		bottom: -20px;
	}
}

@import './components/GroupHeader/style.scss';
@import './components/TaskGroup/style.scss';
@import './components/TaskRow/style.scss';

