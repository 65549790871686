.LocationTreeList {
	display: block;

	.rst__moveHandle {
		//background-color: red;
		width: 22px;
		//background: $white;
		//background-color: color('green', 'base');
	}

	.rst__rowContents {
		padding: 0 5px 0 0px;
		min-width: 500px;
	}

	.locationItem {
		//width: 80%;

		.locationTitleRow {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}

		&.editing {
			.rst__rowContents {
				background-color: #f6f2ef;
				padding: 0 5px 0 10px;
			}
		}

		&.errored {
			.rst__rowContents {
				border: 1px solid red;
			}
			//border-color: red !important;
		}
	}
	.locationItemEditor {
		&.editing {
			//background-color: #f6f2ef;
			border-bottom: 1px solid #e5e5e5;
		}
	}

	.locationButtons {
		//background-color: red;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.info-button {
			margin-right: 2px;
			cursor: pointer;
		}

		.info-chip {
			margin-right: 4px;
			//background-color: #6ab7e4;
			//color: $white;
		}

		.tag-chip {
			margin-right: 4px;
		}
	}
}
