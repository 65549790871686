.transform-none {
	text-transform: none !important;
}

.AppHeader {
	//background-color: red;
	position: fixed;
	//position: absolute;
	padding: 4px 8px;
	width: 100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;

	z-index: 300;
	zoom: 1;
	background-color: #f6f2ef;
	opacity: 0.95;
	height: 40px;

	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;

	.MuiToolbar-regular {
		min-height: unset;
	}
}

.add-filter-popover {
	width: 340px;

	.popover-inner {
		width: 340px;
	}
}

.body-wrapper {
	padding-top: 44px;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.row {
	display: flex;
	flex-direction: row;
	justify-items: flex-start;
}

.filter-selector-popover {
	z-index: 10000 !important;
	.popover-inner {
		.popover-body {
			padding: 0px;
		}
	}
}

.user-nav-popover {
	z-index: 4000 !important;
	.popover-inner {
		.popover-body {
			padding: 0px;
		}
	}
}

.MuiButton-containedSecondary {
	color: white !important;
}

.or-btn {
	padding-left: 16px;
}

.filter-link {
	border-bottom: 1px dotted #477b96;
	color: #0047b3;
}

@import './legacy.scss';
@import './routes/style.scss';
@import './components/style.scss';
@import './containers/style.scss';
