.OperationNew {
	//max-width: 700px;

	h2 {
		margin: 0px;
		font-weight: initial;
	}

	.buttons {
		margin-top: 10px;
	}

	.back-btn {
		margin-right: 16px;
	}

	.form-buttons {
		margin-left: 8em;
		margin-right: 8em;
	}

	.indent-actions {
		margin-left: 8em;
		margin-right: 8em;
	}
}
