.TaskObserveSelectionBar {
   display: flex;
   flex-direction: row;
   width: 100%;
   justify-content: center;
   padding-top: 8px;
   padding-bottom: 16px;

   .SelectionButtons {

      display: flex;
      flex-direction: row;
      width: fit-content;
      background-color: #E8E8E8;
      border-radius: 4rem;


      .SelectionButton {

          span {
            text-align: center;
          }

         min-width: 100px;
         color: #273C47;
         margin: 2px;
         background-color: #E8E8E8;
         border: none;
         &.MuiToggleButton-root.Mui-selected {
            background-color: white;
            color: unset !important;
         }
      }
   }
   
}