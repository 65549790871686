
.BreadCrumbs {

	display: block;

	width: 92%;
	//max-width: 53em;
	//padding: 0.2rem 0;
	padding: 0.2em 0;;

	margin: 0.1rem auto 0;
	background: #fff;
	border-bottom: 1px solid rgba(0,0,0,0.03);
	box-shadow: 0 1px 4px rgba(0,0,0,0.1), 0 10px 30px #f3ece8;
	border-radius: 0.2em 0.2em 0 0;
	cursor: pointer;

	&.center {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

	}

	.crumb {
		text-decoration: underline;
		font-size: 1.25em;

	}

	.gap {
		font-size: 0.8em;
    padding: 4px;
	}

}
