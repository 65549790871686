
.date-selector-popover {

	max-width: 365px;

	.popover-inner {
		.popover-body {
			padding: 0px;

			.timeframes {
				padding-top: 50px;
			}
			.timeframe-btn {
				//border-bottom: 1px dotted #477b96;
				//color: #0047b3;
				//text-align: left;
				display:block;
			}
		}
	}
}



.PriorDateSelector {

	display: block;

	.date-control {
		display: flex;
		flex-direction: column;

		label {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.54);

		}
	}
	.filter-link {
		display: inline-block;
	}


}
