

.popover {
	z-index: 10;
	

	.popover-body {
		padding: 15px 20px;
		background-color: #FFFCF9;
		border: 1px solid #000;
		overflow-x: hidden;
	}

	.marker {		
		font-size: 7px;
		width: 3em;
		height: 1em;
		&::before {
			margin: auto;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-style: solid;			
		}
	}	

	&[x-placement="bottom"] .marker-top {
		margin-top: -0.9em;

		&::before {

			border-color: transparent transparent #000 transparent;
			border-width: 0 1em 1em 1em;
		}
	}

	&[x-placement="bottom"] .marker-bottom  { display: none; }
	&[x-placement="bottom"] .marker-left  { display: none; }
	&[x-placement="bottom"] .marker-right  { display: none; }


	&[x-placement="top"] .marker-bottom {
		margin-bottom: -0.9em;

		&::before {

			border-color:  #000 transparent transparent transparent;
			border-width: 1em 1em 0 1em;
		}
	}

	&[x-placement="top"] .marker-top  { display: none; }
	&[x-placement="top"] .marker-left  { display: none; }
	&[x-placement="top"] .marker-right  { display: none; }


	&[x-placement="left"] .marker-right {
		margin-right: -0.9em;
		height: 3em;
		width: 1em;


		&::before {

			border-color: transparent transparent transparent #000;
			border-width: 1em 0 1em 1em;
		}
	}

	&[x-placement="left"] .marker-bottom  { display: none; }
	&[x-placement="left"] .marker-left  { display: none; }
	&[x-placement="left"] .marker-top  { display: none; }

	
	&[x-placement="right"] .marker-left {
		margin-left: -0.9em;
		height: 3em;
		width: 1em;


		&::before {

			border-color: transparent #000  transparent transparent;
			border-width: 1em 1em 1em 0;
		}
	}

	&[x-placement="right"] .marker-bottom  { display: none; }
	&[x-placement="right"] .marker-right  { display: none; }
	&[x-placement="right"] .marker-top  { display: none; }


	/*
	&[x-placement="bottom"] .arrow {
		top: 0px;
		left: 0px;
		margin-top: -0.9em;

		&::before {

			border-color: transparent transparent #fff transparent;
			border-width: 0 1em 1em 1em;
		}
	}

	&[x-placement="top"] .arrow {
		bottom: 0px;
		left: 0px;
		margin-bottom: -0.9em;

		&::before {
			border-color:  #fff transparent transparent transparent;
			border-width: 1em 1em 0 1em;
		}
	}

	&[x-placement="right"] .arrow {
		left: 0;
		margin-left: -0.9em;
		height: 3em;
		width: 1em;

		&::before {
			border-color: transparent #fff  transparent transparent;
			border-width: 1em 1em 1em 0;
		}
	}

	&[x-placement="left"] .arrow {
		right: 0;
		margin-right: -0.9em;
		height: 3em;
		width: 1em;

		&::before {
			border-color: transparent transparent transparent #fff;
			border-width: 1em 0 1em 1em;
		}
	}
	
	.arrow {
		position: absolute;
		font-size: 7px;
		width: 3em;
		height: 1em;

		&::before {
			margin: auto;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-style: solid;

		}
	}

	*/
}
