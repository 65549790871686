.AnnualSelectedMonthDatesPicker {
	background-color: #fff;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 8px;
	padding-right: 4px;
	max-width: 250px;

	.MuiToggleButtonGroup-root {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;

		.ToggleButton {
			margin-right: 2px;

			.day-btn {
				margin-top: 6px;
				min-width: unset;
				width: 30px;
				height: 30px;
			}

			.last-btn {
				width: 128px;
				margin-top: 6px;
				height: 30px;
				margin-bottom: 10px;
			}

			.month-btn {
				margin-top: 6px;
				width: 35px;
				height: 30px;
			}

			.rounded {
				min-width: unset;
			}
		}
	}
}
