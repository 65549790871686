.ActPage {
   padding: 20px;
   flex: 1;
   display: flex;
   min-height: 100vh;
      
   .full-iframe-container {      
      flex: 1;
      width: 100%;
      border: none;      
      overflow: visible;
      position: relative;
      margin-bottom: 60px;      
   }

   .full-frame {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: none;
   }
}


