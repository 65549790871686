.PositionFilter {
	display: inline-block;
	//background-color: red;
	margin: 0px;
	padding: 0px;

	.filter-toolbar {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;

		.toolbar-center {
			margin-left: auto;
			margin-right: auto;
		}

		.toolbar-left {
			margin-left: 0px;
			min-width: 50%;
		}

		.toolbar-right {
			margin-left: auto;
		}

		.position-filter-group {
			margin-right: 6px;
			display: inline-block;
			font-size: 16px;
		}
	}
}
