.TaskFilters {
    height: 100%;
    position: relative;
    //width: 300px;
    display: flex;
    
    flex-direction: row;
}

.menu {
    background: #fff;    
    height: 100%;
    overflow-y: auto;
    min-width: 300px;
}

.applied-filters {
    margin-bottom: -4px;
    padding: 16px 24px 0;

    .heading-clear-all {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: bold;
        height: 32px;
        margin: 0 0 8px;
        user-select: none;

        .heading {
            color: color('primary', 'main');
        }

        .clear-all {
            color: #b27c31;
            cursor: pointer;
            margin-left: auto;
        }
    }
}


.filter-item {
   border-bottom: 1px solid #ccc;
   user-select: none;

   &:hover {
       background: #f1f5fb;
   }

   .filter-item-link {
       align-items: center;
       display: flex;
       height: 48px;
       padding: 0 24px;
       position: relative;

       .label {
           font-size: 12px;
           font-weight: 700;
           letter-spacing: 1px;
           text-transform: uppercase;
       }

       .count {
           color: #999;
           //display: none; // temp
           font-size: 11px;
           font-weight: 500;
           margin-left: 4px;
       }

       .icon-wrap {
           align-items: center;
           display: flex;
           position: absolute;
           right: 17px;
       }
   }

   .filter-chips {
       display: block;
       padding: 0 24px 20px;

       &:empty {
           padding: 0;
       }
   }
}

.sub-menus {
    border-left: 1px solid #ccc;
    box-shadow: 24px 0 24px -12px rgba(0, 0, 0, 0.25);
    display: flex;
    height: 100%;
    left: 100%;
    //position: absolute;
    top: 0;
    z-index: 1;

    .filter-options-menu {
        width: 511px;

        // pseudo content provides additional hover area to prevent submenu closing accidentally
        // e.g. when user is hovering cursor over the filter panel character index
        &::after {
            content: "";
            display: block;
            height: 100%;
            left: 100%;
            //position: absolute;
            top: 0;
            width: 48px;
            z-index: -1;
        }
    }

    
}


@import './components/FilterPanel/style.scss';
@import './components/FilterChips/style.scss';
@import './components/FilterSwitch/style.scss';
@import './components/FilterPriorDateSelector/style.scss';