.section-popover {


	z-index: 500 !important;


	.arrow {
		z-index: 500 !important;
	}

	.popover-inner {
		.popover-body {
			padding: 0px;
			//background-color: red;
		}
	}


}

.SectionNav {

	display:block;
	min-width: 100px;
	cursor: pointer;
	//background-color: red;


	.section-link {
		border-bottom: 1px dotted #757579;
		//color: #9494a0;
		//color: #000;
		//color:whitesmoke;
		font-size: 18px;
		margin-right: 8px;
	}

}