.repeater-selector-popover {
	z-index: 10000;

	.popover-inner {
		background-color: white;
		border: 1px solid #5086a0;
		min-width: 200px;
	}

	.selected-chip {
		margin: 4px;
	}

	.IntegerInput {
		input {
			color: white;
			font-size: 20px;
		}
	}

	label {
		color: white;
	}

	&[x-placement='bottom'] .marker-top {
		margin-top: -0.9em;

		&::before {
			border-color: transparent transparent #5086a0 transparent;
		}
	}
}

.int-repeater-selector-popover {
	z-index: 10000;

	.popover-inner {
		background-color: white;
		border: 1px solid #5086a0;
		//border: 1px solid red;

		padding: 10px;
		min-width: 80px;
	}

	.IntegerInput {
		input {
			//color: white;
			font-size: 20px;
		}
	}

	label {
		color: white;
	}

	&[x-placement='bottom'] .marker-top {
		margin-top: -0.9em;

		&::before {
			border-color: transparent transparent #5086a0 transparent;
		}
	}
}

.RepeatTimesSelector {
	display: inline-block;

	.caption {
		margin-right: 6px;
		display: inline-block;
		font-size: 16px;
	}

	.PopoutSelector {
		margin-right: 6px;
		font-size: 16px;
	}

	.PopoutIntegerSelector {
		margin-right: 6px;
		font-size: 16px;
	}
}
