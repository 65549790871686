.drawer-container {
   
   width: 100%;
   height: 100%;
   overflow: hidden; /* This will ensure no scrollbars appear on the container */

   &.open {
      .drawer-content {
         left: 0;
         opacity: 1;
            transform: none;
      }

      .backdrop {
         opacity: 1;
         visibility: visible;
      }

   }
}

.backdrop {
   position: absolute; /* Change to absolute so it only covers the container div */
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   //background-color: rgba(0, 0, 0, 0.5);
   z-index: 1000;

   background: rgba(0, 0, 0, 0.5);
   //inset: 120px 0 0;
   opacity: 0;   
   transition: opacity 0.3s;
   visibility: hidden;
}


.drawer-content {
   position: absolute;
   height: 100%;
   opacity: 0;
   transform: translate3d(-100%, 0, 0);
   transition:
       opacity 0.2s,
       transform 0.3s;
   min-width: 300px;

   position: absolute;
   left: -250px; /* Adjust width here if necessary */
   top: 0;      
   background-color: white;
   
   z-index: 1001; /* Make sure this is above other content but below the backdrop */
   overflow-y: auto; /* Allows scrolling within the drawer only */

}
