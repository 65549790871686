.TeamsPage {
	.PageHeader {
		//background-color: red;
		//padding-bottom: 20px;
	}

	.empty-arrow {
		position: absolute;
		top: 40px;
		left: 60px;
		z-index: 2;
	}

	.filter-box {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.TeamListHeader {
		margin-left: 20px;
		margin-right: 20px;
	}

	.TeamFilter {
		height: 36px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}
