.EnabledChip {
	display: inline;

	.chip {
		color: white;
		height: unset;
	}

	.chip-enabled {
		background-color: green;
	}

	.chip-disabled {
		background-color: orange;
	}
}
