.StaffList {
	display: block;
	min-height: 400px;

	.pagination {
		//background-color: red;
		position: absolute;
		bottom: -20px;
	}
}

@import './components/StaffListItem/style.scss';
