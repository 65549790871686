
.TagSelector {

	.tagrow {
		display:flex;
		flex-direction: row;
		justify-items: flex-start;
	}

	.col-letter {
		max-width: 60px;

		.letter {
			margin: 8px;
		}
	}

	.col-tags {

		margin-top: 4px;

		.tag-chip {
			margin: 8px;
		}
	}
}

