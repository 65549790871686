.AccountPage {

   .admin-area {
      padding-top: 16px;
      display: flex;
      flex-direction: column;      
   }

   .admin-link {
      text-decoration: none;
      color: color('primary', 'main');
      font-size: 16px;;
      margin-bottom: 8px;
   }

   .admin-btn {
      background-color: color('primary', 'main');
   }

   .admin-icon {      
      //display: inline-block;
      // width: 3rem;
      // height: 3rem;
      margin: -0.3rem 0.5rem -0.2rem -0.5rem;
      border-radius: 0.2rem;
      
      vertical-align: middle;
      background-color: color('primary', 'main');;
   }
}