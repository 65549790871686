.PeopleSelectionBar {
   display: flex;
   flex-direction: row;
   width: 100%;
   justify-content: center;
   padding-top: 8px;
   padding-bottom: 16px;

   .SelectionButtons {

      display: flex;
      flex-direction: row;
      width: fit-content;

      .SelectionButton {
         margin-left: 12px;
         min-width: 160px;
         font-size: 8px;
      }
   }
}